import naanImage from "./allimages/naan.png";
import limsImage from "./allimages/lims.png";
import grilledImage from "./allimages/grilled.png";
import loadedImage from "./allimages/loaded-nachos.png";
import shreddedledhImage from "./allimages/shreddedledh.png";
import contactImage from "./allimages/contact-image.jpg";
import bgImage from "./allimages/bg3.jpg";
import pro1Image from "./allimages/pro1.jpg";
import pro2Image from "./allimages/pro2.jpg";
import pro3Image from "./allimages/pro3.jpg";
import pro4Image from "./allimages/pro4.jpg";
import pro5Image from "./allimages/pro5.jpg";
import stoyBgImage from "./allimages/storybg.jpg";
import footerlogo from "./allimages/footer-logo.png";
import ourStoryimage from "./allimages/our-story-image.jpg";
import headerLogo from "./allimages/site-logo.png";
import naantacosImages from "./allimages/naantacos.png";
import streettacosImages from "./allimages/streettacos.png";
import chalupaImages from "./allimages/chalupa.png";
import tawatartosImages from "./allimages/tawatartos.png";
import burritoImages from "./allimages/burrito.png";
import customizeOwnTacoImages from "./allimages/customize-own-taco.jpg";
import naanmenuImages from "./allimages/naan-tacos.png";
import naanBurritoImages from "./allimages/naan-burrito.jpg";
import tortosImages from "./allimages/tortas.png";
import chalupaMenuImages from "./allimages/chalupaMenuImages.png";
import bowlImages from "./allimages/bowl.png";
import spicedChickenImage from "./allimages/special-chicken.png";
import malaliTikkaChickenImage from "./allimages/malai-tikka-chicken.png";
import grilledChikenImage from "./allimages/grilled-chicken.png";
import plantBasedChikenImage from "./allimages/plant-based-chicken.png";
import greekLambImage from "./allimages/greek-lamb.png";
import paneerTikkaImage from "./allimages/paneer-tikka.png";
import veggieLaddoImages from "./allimages/veggie-laddo.png";
import aaloTikkaImages from "./allimages/aalo-tikka.png";
import cotijaCheeseImages from "./allimages/cotija-cheese.png";
import pepperJackImages from "./allimages/pepper-jack.png";
import tikkaImages from "./allimages/tikka.png";
import malaoTikkaImages from "./allimages/malai-tikka.png";
import tandoriImages from "./allimages/tandori.png";
import curryImages from "./allimages/curry.png";
import manchurianImages from "./allimages/manchurian.png";
import mangoPickleImages from "./allimages/mango-pic.png";
import cilantroLimeImage from "./allimages/cilantro-lime-rice.png";
import GuacamoleImage from "./allimages/cilantro.png";
import SourCreamImage from "./allimages/sour-cream.png";
import BlackBeansImage from "./allimages/black-beans.png";
import PintoBeansImage from "./allimages/pinto-beans.png";
import OnionImage from "./allimages/onion.png";
import TomatoesImage from "./allimages/tomatoes.png";
import ShreddedLettuceImage from "./allimages/shreddeed-lettuce.png";
import PurpleCabbageImage from "./allimages/purple-cabbage.png";
import PickledJalapenosImage from "./allimages/pickled-jalapenos.png";
import CilantroImage from "./allimages/cilantroMenu.png";
import GrilledVeggiesImage from "./allimages/grilled-veggies.png";
import AlooBhujiaImage from "./allimages/aloo-bhujia.png";
import SidesImage from "./allimages/sides.png";
import wingsImage from "./allimages/wings.png";
import salsaChipImage from "./allimages/salsa-and-chips.png";
import specialityImage from "./allimages/specialty-meals.png";
import popBevargesImage from "./allimages/pop-beverages.png";
import SidesdropdwonImage from "./allimages/sidesImage.png";
import specialtyMealsdropdownImages from "./allimages/specialty-mealsdropdown.png";
import sliderImage1Image from "./allimages/home-slide1.png";
import sliderImage2Image from "./allimages/menu-banner-2.jpg";
import menuBannerImage from "./allimages/menu-banner.jpg";
import menuBanner4Image from "./allimages/menu-banner-4.jpg";
import menuBanner5Image from "./allimages/banner-coupon.jpg";
import userImage from "./allimages/userImage.png";
import locationimage from "./allimages/locationImage.jpg";
import chatBotIcon from "./allimages/chatboat_icon1.jpg";
import sliderLocation from "./singapore-november-06-2017-little-child-buying-fast-food-mcdonald-s-restaurant-sentosa-island-singapore_175634-31152.avif";
import Fairfield from "./c-city.png";
import downtwon from "./Downtown.jpg";
import saint from "./Saint-l.jpg";
import elkGrove from "./Elk Groove.jpg";
import frisco from "./ptfrisco.png";
import frsno from "./Fresno.jpg"
import yubacity from "./Yuba city.jpg"
import kent from "./kent.png"
import seatle from "./seattle Wa.jpg"
import roseville from "./Roseville CA.jpg"
import gateway from "./GatewayPark.jpg"
import woodland from "./Woodland_CA_img.png"
import antelope from "./antelope.png"


export const allImages = {
  naanImage,
  limsImage,
  grilledImage,
  loadedImage,
  shreddedledhImage, Fairfield, downtwon, saint, elkGrove,frisco, frsno,yubacity,kent,seatle,roseville,gateway,
  contactImage,
  bgImage,
  pro1Image,
  pro2Image,
  pro3Image,
  pro4Image,
  pro5Image,
  stoyBgImage,
  footerlogo,
  ourStoryimage,
  headerLogo,
  naantacosImages,
  streettacosImages,
  customizeOwnTacoImages,
  chalupaImages,
  tawatartosImages,
  burritoImages,
  naanmenuImages,
  naanBurritoImages,
  tortosImages,
  chalupaMenuImages,
  bowlImages,
  spicedChickenImage,
  malaliTikkaChickenImage,
  grilledChikenImage,
  plantBasedChikenImage,
  greekLambImage,
  paneerTikkaImage,
  veggieLaddoImages,
  aaloTikkaImages,
  cotijaCheeseImages,
  pepperJackImages,
  tikkaImages,
  malaoTikkaImages,
  tandoriImages,
  curryImages,
  manchurianImages,
  mangoPickleImages,
  cilantroLimeImage,
  GuacamoleImage,
  SourCreamImage,
  BlackBeansImage,
  PintoBeansImage,
  OnionImage,
  TomatoesImage,
  PurpleCabbageImage,
  ShreddedLettuceImage,
  PickledJalapenosImage,
  CilantroImage,
  GrilledVeggiesImage,
  AlooBhujiaImage,
  SidesImage,
  wingsImage,
  salsaChipImage,
  specialityImage,
  popBevargesImage,
  SidesdropdwonImage,
  specialtyMealsdropdownImages,
  sliderImage1Image,
  sliderImage2Image,
  menuBannerImage,
  menuBanner4Image,
  menuBanner5Image,
  userImage,
  locationimage,
  chatBotIcon, 
  sliderLocation,
  woodland,
  antelope,
};
